<template>
  <v-container>
    <div class="confirm-container mx-auto">
      <v-row no-gutters class="text-center">
        <v-col cols="12">
          <v-img
            src="/img_layout/registration-confirmed.png"
            alt="registrazione completata"
            width="80"
            class="mx-auto"
          />
          <h1>{{ $t("register.completed.title") }}</h1>
          <!-- <div class="msg-body mb-3">
            {{
              socialLogin
                ? $t("register.completed.textSocialLogin")
                : $t("register.completed.text", [mail])
            }}
          </div> -->

          <p
            v-if="selfScanning"
            class="confirm mt-5 mb-3 font-weight-bold"
            v-html="$t('register.completed.selfScanning')"
          ></p>

          <p
            v-else-if="!socialLogin"
            class="confirm mt-5 mb-3 font-weight-bold"
          >
            {{ $t("register.completed.socialConfirm") }}
          </p>

          <div v-if="!agreement && !socialLogin && !selfScanning">
            <p class="warning-text font-weight-bold">
              {{ $t("register.completed.warning") }}
            </p>
          </div>
          <p v-if="agreement">
            <strong>{{ $t("register.completed.reminder") }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="btn-container align-center justify-center">
        <div v-if="selfScanning">
          <img
            class="selfscanning-img rounded-lg"
            src="/img_layout/zebra-sc.jpg"
          />
          <a
            href="/"
            class="rounded-pill w-100 px-6 v-btn v-btn--has-bg theme--light v-size--large primary"
            >{{ $t("register.completed.goToHomeSelfScannig") }}</a
          >
        </div>
        <div v-else>
          <v-btn
            large
            depressed
            color="primary"
            class="rounded-pill d-block mx-auto"
            min-width="200"
            @click="goToHome"
            >{{ $t("register.completed.goToHome") }}</v-btn
          >
        </div>
      </v-row>
    </div>
  </v-container>
</template>
<style lang="scss">
.selfscanning-reg-instructions {
  p {
    text-align: left !important;
    font-size: 1.3rem !important;
    font-weight: 600 !important;
  }
  ul {
    text-align: left !important;
    ol {
      font-size: 1.3rem !important;
      font-weight: 400 !important;
      padding-left: 0 !important;
    }
  }
}
.selfscanning-img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  max-height: 220px;
}
.confirm-container {
  width: 66%;
  padding: 35px 0 0x;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
  h1 {
    font-size: 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 25px;
    }
  }
  .msg-body,
  .confirm,
  .warning-text {
    font-size: 20px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 16px;
      line-height: 1.15;
    }
  }
  .warning-text {
    color: #f80100;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";
export default {
  name: "RegistrationCompleted",

  data() {
    return {
      mail: "",
      socialLogin: null,
      agreement: null
    };
  },
  mixins: [categoryMixins],
  methods: {
    async goToHome() {
      if (await this.needAddress()) {
        if (await this.needTimeslot()) {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }
      }
    }
  },
  created() {
    this.mail = this.$route.params.mail;
    this.socialLogin = this.$route.params.socialLogin;
    this.agreement = this.$route.params.agreement;
  }
};
</script>
